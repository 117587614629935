/* eslint-disable max-lines */
import {
  PreflightRequestStatus,
  QueueStatus,
} from '@transcend-io/privacy-types';
import { makeEnum } from '@transcend-io/type-utils';

/**
 * Methods that sombra can be hosted
 */
export enum SombraHostedMethod {
  /** The default sombra configuration, we store the env config in the db, encrypted by the multi-tenant sombra */
  MultiTenant = 'MULTI_TENANT',
  /** Transcend is hosting sombra on behalf of the organization, but they have their own dedicated sombra cluster */
  SingleTenant = 'SINGLE_TENANT',
  /** The organization is hosting sombra within their firewall */
  OnPremise = 'ON_PREMISE',
}

/**
 * On-Premise Sombra creation method.
 */
export enum OnPremiseSombraCreationMethod {
  /**
   * Use the Piko cluster proxy method.
   * a.k.a. the Sombra Proxy method.
   * a.k.a. the Reverse Tunnel method.
   */
  ReverseTunnel = 'REVERSE_TUNNEL',
  /** Do all the setup yourself. */
  DirectConnection = 'DIRECT_CONNECTION',
}

/**
 * The set of supported key management providers
 */
export enum SombraKMSProvider {
  /** The local key management */
  Local = 'local',
  /** AWS key management */
  AWS = 'AWS',
}

/** Plugin types. */
export enum PluginType {
  DataSiloDiscovery = 'DATA_SILO_DISCOVERY',
  SchemaDiscovery = 'SCHEMA_DISCOVERY',
  ContentClassification = 'CONTENT_CLASSIFICATION',
  LookupProcess = 'LOOKUP_PROCESS',
  CreateLookupProcess = 'CREATE_LOOKUP_PROCESS',
  UnstructuredDataDiscovery = 'UNSTRUCTURED_DATA_DISCOVERY',
  UnstructuredDataSampling = 'UNSTRUCTURED_DATA_SAMPLING',
}

export const SombraClassificationMethod = makeEnum({
  /** Classification step using customer-provided regex classification */
  CUSTOM_REGEX_CLASSIFY: 'CUSTOM_REGEX_CLASSIFY',
  /** Classification step using Transcend-provided LLM */
  TRANSCEND_LLM_CLASSIFY: 'TRANSCEND_LLM_CLASSIFY',
});

/** type overload */
export type SombraClassificationMethod =
  (typeof SombraClassificationMethod)[keyof typeof SombraClassificationMethod];

export const UnstructuredClassificationMethod = makeEnum({
  ...SombraClassificationMethod,
  /** Entity was manually classified */
  MANUAL: 'MANUAL',
});

/** type overload */
export type UnstructuredClassificationMethod =
  (typeof UnstructuredClassificationMethod)[keyof typeof UnstructuredClassificationMethod];

export const ClassificationMethod = makeEnum({
  ...SombraClassificationMethod,
  /**
   * Classification step using an ML model that considers vectorized samples and column metadata
   *
   * @deprecated
   */
  VECTORIZED_ML_CLASSIFY: 'VECTORIZED_ML_CLASSIFY',
  /** Classification step using hard-coded regexes and rules on the property name */
  PROPERTY_NAME_MATCH: 'PROPERTY_NAME_MATCH',
  /** Classification using AWS Bedrock API */
  LLM_API: 'LLM_API',
});

/** type overload */
export type ClassificationMethod =
  (typeof ClassificationMethod)[keyof typeof ClassificationMethod];

export const SombraJobType = makeEnum({
  ...SombraClassificationMethod,
});

/** type overload */
export type SombraJobType = (typeof SombraJobType)[keyof typeof SombraJobType];

/**
 * The various states the Content classification plugin Lambda function can handle,
 * and save to DDB for the client application (i.e. the backend)
 * to handle.
 *
 */
export const ContentClassificationJobStatus = makeEnum({
  /** Retry the indexing request in some time. */
  Retry: 'RETRY',
  /** A-OK. */
  Success: 'SUCCESS',
  /** Classification attempt completed without success */
  Failure: 'FAILURE',
  /** Generic error that should be bubbled up to the client. */
  Error: 'ERROR',
  /** The plugin run was skipped. */
  Skipped: 'SKIPPED',
});

/** Override type */
export type ContentClassificationJobStatus =
  (typeof ContentClassificationJobStatus)[keyof typeof ContentClassificationJobStatus];

/**
 * The types of authentications supported by sombra
 */
export const SombraDataSubjectAuthMethod = makeEnum({
  /** A long-lived (5 day) session issued by sombra, used for both employees and data subjects */
  Session: 'session',
  /** A JWT signed by the organization's auth server */
  Jwt: 'jwt',
  /** The auth code from oauth redirect */
  OAuth: 'oauth',
  /** Transcend logs in the data subject */
  Transcend: 'transcend',
  /** No authentication is required */
  NoAuth: 'noAuth',
});

/** Type override */
export type SombraDataSubjectAuthMethod =
  (typeof SombraDataSubjectAuthMethod)[keyof typeof SombraDataSubjectAuthMethod];

/**
 * The types of authentications supported by sombra
 */
export const SombraEmployeeAuthMethod = makeEnum({
  /** A long-lived (5 day) session issued by sombra, used for both employees and data subjects */
  Session: 'session',
  /** The organization's SSO provider */
  Saml: 'saml',
  /** Transcend logs in the data subject */
  Transcend: 'transcend',
});

/** Type override */
export type SombraEmployeeAuthMethod =
  (typeof SombraEmployeeAuthMethod)[keyof typeof SombraEmployeeAuthMethod];

/** The sombra roles for authorization */
export const SombraRole = makeEnum({
  Vendor: 'vendor',
  Employee: 'employee',
  DataSubject: 'data-subject',
});

/** Type override */
export type SombraRole = (typeof SombraRole)[keyof typeof SombraRole];

/**
 * Transitions that an enricher can change the request status to
 */
export const AllowedEnricherTransition = makeEnum({
  ...PreflightRequestStatus,
  // TODO: https://transcend.height.app/T-10357 - below should be deprecated
  FAILED_VERIFICATIONS: 'FAILED_VERIFICATION',
  COMPILING: 'COMPILING',
});

/**
 * Overload type
 */
export type AllowedEnricherTransition =
  (typeof AllowedEnricherTransition)[keyof typeof AllowedEnricherTransition];

export const KnownDataType = makeEnum({
  String: 'string',
});

/** type overload */
export type KnownDataType = (typeof KnownDataType)[keyof typeof KnownDataType];

export const MongoSupportedQuery = makeEnum({
  findOne: 'findOne',
  find: 'find',
  updateOne: 'updateOne',
  updateMany: 'updateMany',
  deleteOne: 'deleteOne',
  deleteMany: 'deleteMany',
  replaceOne: 'replaceOne',
  listDatabases: 'listDatabases',
  listCollections: 'listCollections',
  aggregate: 'aggregate',
  /** Sample both random documents and newest documents with two MongoDB queries */
  sample: 'sample',
  /** Sample only random documents */
  sampleRandom: 'sampleRandom',
  /** Sample only newest documents */
  sampleNewest: 'sampleNewest',
});

/** type overload */
export type MongoSupportedQuery =
  (typeof MongoSupportedQuery)[keyof typeof MongoSupportedQuery];

export const DynamoDBSupportedQuery = makeEnum({
  delete: 'delete',
  query: 'query',
  scan: 'scan',
  listTables: 'listTables',
  describeTable: 'describeTable',
});

/** type overload */
export type DynamoDBSupportedQuery =
  (typeof DynamoDBSupportedQuery)[keyof typeof DynamoDBSupportedQuery];

export const GenericAWSSupportedQuery = makeEnum({
  DescribeRDSDBInstances: 'DESCRIBE_RDS_DB_INSTANCES',
  DescribeRedshiftClusters: 'DESCRIBE_REDSHIFT_CLUSTERS',
  ListRedshiftDatabases: 'LIST_REDSHIFT_DATABASES',
  ListS3Buckets: 'LIST_S3_BUCKETS',
  ListDynamoDBTables: 'LIST_DYNAMODB_TABLES',
  ListAccounts: 'LIST_ACCOUNTS',
  ListAccountsForParent: 'LIST_ACCOUNTS_FOR_PARENT',
});

/** type overload */
export type GenericAWSSupportedQuery =
  (typeof GenericAWSSupportedQuery)[keyof typeof GenericAWSSupportedQuery];

export const AWSSupportedQuery = makeEnum({
  ...GenericAWSSupportedQuery,
  GetBucketLocation: 'GET_BUCKET_LOCATION',
});

/** type overload */
export type AWSSupportedQuery =
  (typeof AWSSupportedQuery)[keyof typeof AWSSupportedQuery];

export const SftpSupportedCommand = makeEnum({
  Put: 'put',
  Get: 'get',
  CheckRequestStatus: 'checkRequestStatus',
});

/** type overload */
export type SftpSupportedCommand =
  (typeof SftpSupportedCommand)[keyof typeof SftpSupportedCommand];

export const SftpSupportedFileType = makeEnum({
  Csv: 'csv',
});

/** type overload */
export type SftpSupportedFileType =
  (typeof SftpSupportedFileType)[keyof typeof SftpSupportedFileType];

export const CdataOdbcSupportedCommand = makeEnum({
  FetchTables: 'fetchTables',
  FetchColumns: 'fetchColumns',
});

/** type overload */
export type CdataOdbcSupportedCommand =
  (typeof CdataOdbcSupportedCommand)[keyof typeof CdataOdbcSupportedCommand];

export const SmbSupportedCommand = makeEnum({
  /** Get current working directory */
  Cwd: 'cwd',
  /** List all files/folders in provided path */
  List: 'list',
  /** List all files/folders recursively in provided path */
  RecursiveList: 'recursiveList',
});

/** type overload */
export type SmbSupportedCommand =
  (typeof SmbSupportedCommand)[keyof typeof SmbSupportedCommand];

/**
 * The types of messages that can be sent to a data subject
 *
 * TODO should have common enum with overlapping templates
 */
export const CommunicationMessage = makeEnum({
  /** A cancellation message was sent to the data subject  */
  CancelationSent: 'CANCELATION_SENT',
  /** A continuation message was sent to the data subject  */
  ContinuationSent: 'CONTINUATION_SENT',
  /** A reminder email sent message was sent to the data subject  */
  ReminderSent: 'REMINDER_SENT',
  /** An email to provision the user as a new role */
  ProvisionedRole: 'PROVISIONED_ROLE',
  /** Privacy center login attempt */
  PrivacyCenterLogin: 'PRIVACY_CENTER_LOGIN',
  /**
   * A message to prompt a vendor to comply with DSR(s).
   * Formerly known as Prompt-a-vendor.
   */
  AutomatedVendorCoordination: 'AUTOMATED_VENDOR_COORDINATION',
  /** The DSR report was sent to the user */
  ReportSent: 'REPORT_SENT',
  /** The secondary report was sent to the user */
  SecondaryReportSent: 'SECONDARY_REPORT_SENT',
  /** A verification message for an identifier */
  IdentifierVerificationSent: 'IDENTIFIER_VERIFICATION_SENT',
  /** A verification message for a request that fails verification */
  FailedVerificationSent: 'FAILED_VERIFICATION_SENT',
  /** An email verifying the data subject revoked their request */
  RevokedSent: 'REVOKED_SENT',
  /** An email receipt was sent */
  ReceiptSent: 'RECEIPT_SENT',
  /** A message to request additional time */
  AdditionalTimeSent: 'ADDITIONAL_TIME_SENT',
  /** An arbitrary message from organization to data subject  */
  OrganizationToDataSubjectSent: 'ORGANIZATION_TO_DATA_SUBJECT_SENT',
  /** An arbitrary message from data subject to organization */
  DataSubjectToOrganizationSent: 'DATA_SUBJECT_TO_ORGANIZATION_SENT',
  /** A message to remote SaaS that we are waiting on a response from */
  WaitingOnRemoteSaaSResponse: 'WAITING_ON_REMOTE_SAAS_RESPONSE',
  /** A message to remote SaaS that bounced */
  FailedToSendToRemoteSaaS: 'FAILED_TO_SEND_TO_REMOTE_SAAS',
  /** A reply from remote SaaS to one of our emails */
  ReplyToRequestEmail: 'REPLY_TO_REQUEST_EMAIL',
  /** An action item report sent to the user */
  ActionItemReportSent: 'ACTION_ITEM_REPORT_SENT',
  /** An automated vendor coordination token sent to the vendor */
  AutomatedVendorCoordinationTokenSent:
    'AUTOMATED_VENDOR_COORDINATION_TOKEN_SENT',
  /** A password reset email sent to the user */
  PasswordResetSent: 'PASSWORD_RESET_SENT',
  /** The user has been assigned the owner of one or more ROPA rows */
  ReviewRopa: 'REVIEW_ROPA',
  /** The user needs to authenticate through his phone */
  PhoneMessage: 'PHONE_MESSAGE_ACCESS',
  /** The user needs to authenticate with their government ID */
  GovernmentIdMessage: 'GOVERNMENT_ID_MESSAGE_ACCESS',
  /** User has been sent an assessment email */
  AssessmentEmail: 'ASSESSMENT_EMAIL',
});

/** type overload */
export type CommunicationMessage =
  (typeof CommunicationMessage)[keyof typeof CommunicationMessage];

export const SnowflakeSchemaQueryOperation = makeEnum({
  ListDatabases: 'LIST_DATABASES',
  ListSchemas: 'LIST_SCHEMAS',
  ListTables: 'LIST_TABLES',
  ListColumns: 'LIST_COLUMNS',
});

/** type overload */
export type SnowflakeSchemaQueryOperation =
  (typeof SnowflakeSchemaQueryOperation)[keyof typeof SnowflakeSchemaQueryOperation];

export const FetchSubDatapointsApproach = makeEnum({
  /** `SHOW COLUMNS` approach. */
  ShowColumns: 'SHOW_COLUMNS',
  /** `SELECT * FROM <database>.INFORMATION_SCHEMA.COLUMNS` appraoch. */
  SelectInformationSchema: 'SELECT_INFORMATION_SCHEMA',
});

/** type overload */
export type FetchSubDatapointsApproach =
  (typeof FetchSubDatapointsApproach)[keyof typeof FetchSubDatapointsApproach];

export const AmazonS3SupportedQuery = makeEnum({
  /** GetObjectCommandInput https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-s3/interfaces/getobjectcommandinput.html */
  GetObject: 'getObject',
  /** ListObjectsCommandInput https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-s3/interfaces/listobjectscommandinput.html */
  ListObjects: 'listObjects',
  /** HeadObjectsCommandInput https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/s3/command/HeadObjectCommand/ */
  HeadObject: 'headObject',
  /** ListBucketsCommandInput https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-client-s3/Class/ListBucketsCommand/ */
  ListBuckets: 'listBuckets',
  /** GetBucketLoocationInput https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/s3/command/GetBucketLocationCommand/ */
  GetBucketLocation: 'getBucketLocation',
});

/** type overload */
export type AmazonS3SupportedQuery =
  (typeof AmazonS3SupportedQuery)[keyof typeof AmazonS3SupportedQuery];

export const AzureIntegration = makeEnum({
  /** Azure Blob Storage */
  AzureBlobStorage: 'azureBlobStorage',
});

/** type overload */
export type AzureIntegration =
  (typeof AzureIntegration)[keyof typeof AzureIntegration];

export const AzureBlobServiceSupportedQuery = makeEnum({
  /** https://learn.microsoft.com/en-us/azure/storage/blobs/storage-blob-containers-list-javascript */
  ListContainers: 'listContainers',
  // TODO: https://transcend.height.app/T-33673 - add listBlobsByHierarchy
  /** https://learn.microsoft.com/en-us/azure/storage/blobs/storage-blobs-list-javascript */
  ListBlobsFlat: 'listBlobsFlat',
  // TODO: https://transcend.height.app/T-33675 - add downloadToBuffer
});

/** type overload */
export type AzureBlobServiceSupportedQuery =
  (typeof AzureBlobServiceSupportedQuery)[keyof typeof AzureBlobServiceSupportedQuery];

export const AwsPermission = makeEnum({
  /** List objects inside S3 Buckets */
  S3ListBucket: 's3:ListBucket',
  /** List objects inside S3 Buckets */
  S3ListAllMyBuckets: 's3:ListAllMyBuckets',
  /** Get the content of objects inside S3 Buckets */
  S3GetObject: 's3:GetObject',
  /** Delete DynamoDB Item */
  DynamoDbDeleteItem: 'dynamodb:DeleteItem',
  /** Scan DynamoDB */
  DynamoDbScan: 'dynamodb:Scan',
  /** Query DynamoDB */
  DynamoDbQuery: 'dynamodb:Query',
  /** List DynamoDB tables */
  DynamoDbListTables: 'dynamodb:ListTables',
  /** Describe DynamoDB tables */
  DynamoDbDescribeTables: 'dynamodb:DescribeTable',
  /** Describe redshift tables */
  RdsDescribeDBInstances: 'rds:DescribeDBInstances',
});

/** type overload */
export type AwsPermission = (typeof AwsPermission)[keyof typeof AwsPermission];

export const AwsService = makeEnum({
  /** Amazon S3 */
  AmazonS3: 'amazonS3',
  /** Dynamo DB */
  DynamoDB: 'dynamoDB',
  /** RDS */
  RDS: 'rds',
  /** Redshift */
  Redshift: 'redshift',
  /** Redshift data */
  RedshiftData: 'redshiftData',
  /** Organizations */
  Organizations: 'awsOrganizations',
});

/** type overload */
export type AwsService = (typeof AwsService)[keyof typeof AwsService];

export const AwsIntegration = makeEnum({
  /** Amazon S3 */
  AmazonS3: 'amazonS3',
  /** Dynamo DB */
  DynamoDB: 'amazonDynamodb',
  /** Amazon Web Service */
  AmazonWebService: 'amazonWebServices',
  /** Amazon Web Service - Organizations */
  AwsOrganizations: 'awsOrganizations',
});

/** type overload */
export type AwsIntegration =
  (typeof AwsIntegration)[keyof typeof AwsIntegration];

export const AwsRequestStatus = makeEnum({
  /** Successful Request */
  Success: 'Successful AWS Request',
  /** Unsupported Command Type */
  UnsupportedCommand: 'AWS Command Type Not Supported',
  /** Unsupported Object Type */
  UnsupportedObject: 'AWS S3 Object Type Not Supported',
  /** Could not Find the Requested Resource on AWS */
  ResourceNotFound: 'AWS Resource Not Found',
  /** Unauthorized Request Means Missing AWS Permissions */
  UnauthorizedRequest: 'Missing AWS Permission',
  /** AWS does not know about Transcend's default SubDataPoint */
  QueriedDefaultSubdatapoint: 'Queried the default subdatapoint on AWS',
  /** Invalid Request: Client Did Not Provide Required Parameters */
  InvalidRequest: 'Invalid Request',
  /** PermanentRedirect: The client probably specified a wrong region */
  PermanentRedirect: 'PermanentRedirect',
  /** Request Timeout: Request took too long to process */
  RequestTimeout: 'Request Timeout',
  /** Unknown Error */
  UnknownError: 'Something Bad Happened.',
});

/** type overload */
export type AwsRequestStatus =
  (typeof AwsRequestStatus)[keyof typeof AwsRequestStatus];

/**
 * The bear string to match against
 */
export enum BearerString {
  /** Lower case bearer */
  Lowercase = 'bearer ',
  /** Upper case bearer */
  Capitalized = 'Bearer ',
  /** We create a short lived auth token for prompt a person uploads */
  TranscendJwt = 'Transcend-Jwt ',
  /** A JWT created by our Retool RPC server */
  RetoolJwt = 'Retool-Jwt ',
}

/**
 * Type of SQL parameter
 */
export const SQLQueryParameterType = makeEnum({
  IDENTIFIER: 'IDENTIFIER',
  REQUEST_ID: 'REQUEST_ID',
});

/** type overload */
export type SQLQueryParameterType =
  (typeof SQLQueryParameterType)[keyof typeof SQLQueryParameterType];

/** Statuses for Databricks Lakehouse SQL warehouses */
export enum DatabricksWarehouseStatus {
  STARTING = 'STARTING',
  RUNNING = 'RUNNING',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  DELETING = 'DELETING',
  DELETED = 'DELETED',
}

/** Statuses for Databricks Lakehouse SQL statements */
export enum DatabricksStatementStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
}

/**
 * Supported file types for schema discovery and classification
 */
export enum SupportedS3FileType {
  PARQUET = 'PARQUET',
  JSONL = 'JSONL',
}

/**
 * Supported file types for schema discovery and classification
 */
export enum SupportedGCSFileType {
  PARQUET = 'GCS_PARQUET',
  JSONL = 'GCS_JSONL',
}

/**
 * Supported Actions for Regex Filters
 */
export const RegexFilterActions = makeEnum({
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
});

/** type overload */
export type RegexFilterActions =
  (typeof RegexFilterActions)[keyof typeof RegexFilterActions];

/**
 * Supported Regex Functions for Regex Filters
 * As of now only REGEXP_CONTAINS is supported
 * can add more in the future
 * refer https://cloud.google.com/bigquery/docs/reference/standard-sql/string_functions
 * for more regex functions
 */
export const RegexFilterFunctions = makeEnum({
  REGEXP_CONTAINS: 'REGEXP_CONTAINS',
});

/** type overload */
export type RegexFilterFunctions =
  (typeof RegexFilterFunctions)[keyof typeof RegexFilterFunctions];

/**
 * Test requests
 */
export enum TestRequest {
  /** Access testing */
  Access = 'test+access@transcend.io',
  /** Access testing in all caps to see if the tool is case-sensitive */
  AccessCapital = 'test+ACCESS@transcend.io',
  /** Erasure testing */
  Erasure = 'test+erasure@transcend.io',
  /** Erasure testing in all caps to see if the tool is case-sensitive */
  ErasureCapital = 'test+ERASURE@transcend.io',
  /** Contact opt out testing */
  ContactOptOut = 'test+contact_opt_out@transcend.io',
  /** Contact opt out testing in all caps to see if the tool is case-sensitive */
  ContactOptOutCapital = 'test+CONTACT_OPT_OUT@transcend.io',
  /** Test enriched emails find data for that secondary email */
  Enriched = 'test+enriched@transcend.io',
  /** A test email that is used as a canary to ensure no data is leaked across accounts */
  Canary = 'test+ifyouseethisitsabug@transcend.io',
  /** For testing for an email that should never find data (note: this email is not seeded into remote organization) */
  NoData = 'test+no-data@transcend.io',
  /** Seed mike's email for testing (add your own email to this list if you want mock data to return) */
  Mike = 'mike@transcend.io',
  /** Seed GSuit test email for testing */
  GSuiteTest = 'admin@gsuitetest.dev.trancsend.com',
}

/**
 * BigQuery locations
 *
 * https://cloud.google.com/bigquery/docs/locations#specifying_your_location
 */
export enum BigQueryLocation {
  US = 'US',
  EU = 'EU',
  NoRegion = 'No region',
}

/**
 * BigQuery Client Supported APIs
 */
export enum SupportedBigQueryAPI {
  ListDatasets = 'LIST_DATASETS',
  ListTables = 'LIST_TABLES',
  ListColumns = 'LIST_COLUMNS',
}

/** The keys we use on Sombra. */
export enum SombraKMSKeyType {
  JwtEcdsaKey = 'JWT_ECDSA_KEY',
  KeyEncryptionBase = 'KEY_ENCRYPTION_BASE',
  HmacNonceKey = 'HMAC_NONCE_KEY',
  HmacDataSubjectSessionKey = 'HMAC_DATA_SUBJECT_SESSION_KEY',
  HmacEmployeeSessionKey = 'HMAC_EMPLOYEE_SESSION_KEY',
  EcdhPrivateKey = 'ECDH_PRIVATE_KEY',
  InternalKeyHash = 'INTERNAL_KEY_HASH',
  ConsentIdentifierEncryptionKey = 'CONSENT_IDENTIFIER_ENCRYPTION_KEY',
  JwtEcdsaPublicKey = 'JWT_ECDSA_PUBLIC_KEY',
  EcdhPublicKey = 'ECDH_PUBLIC_KEY',
  JwtAuthenticationPublicKey = 'JWT_AUTHENTICATION_PUBLIC_KEY',
}

/** KMS key sources for Sombra. */
export enum SombraKMSKeySource {
  Aws = 'AWS',
  Sombra = 'SOMBRA',
  Vault = 'VAULT',
}

/** Canary value type. */
export enum SombraKMSCanaryValueType {
  Hash = 'HASH',
  Jwt = 'JWT',
  EncryptedValue = 'ENCRYPTED_VALUE',
}

export const SOMBRA_REPORTABLE_KEYS: SombraKMSKeyType[] = [
  SombraKMSKeyType.JwtEcdsaPublicKey,
  SombraKMSKeyType.JwtAuthenticationPublicKey,
  SombraKMSKeyType.EcdhPublicKey,
  SombraKMSKeyType.KeyEncryptionBase,
  SombraKMSKeyType.HmacNonceKey,
  SombraKMSKeyType.HmacDataSubjectSessionKey,
  SombraKMSKeyType.HmacEmployeeSessionKey,
  SombraKMSKeyType.ConsentIdentifierEncryptionKey,
  SombraKMSKeyType.InternalKeyHash,
];

/**
 * Map of tenant config key name to canary key type mapping.
 *
 * This should correspond with the Reportable keys constant, and a result
 * for asymmetric key-pairs, we use the public key's ID as the ID for the private
 * key too.
 */
export const SOMBRA_KEY_TO_KEY_ID_SOURCE_MAPPING: Record<
  string,
  SombraKMSKeyType
> = {
  JWT_ECDSA_KEY: SombraKMSKeyType.JwtEcdsaPublicKey,
  JWT_ECDSA_PUBLIC_KEY: SombraKMSKeyType.JwtEcdsaPublicKey,
  JWT_AUTHENTICATION_PUBLIC_KEY: SombraKMSKeyType.JwtAuthenticationPublicKey,
  ECDH_PRIVATE_KEY: SombraKMSKeyType.EcdhPublicKey,
  ECDH_PUBLIC_KEY: SombraKMSKeyType.EcdhPublicKey,
  KEY_ENCRYPTION_BASE: SombraKMSKeyType.KeyEncryptionBase,
  HMAC_NONCE_KEY: SombraKMSKeyType.HmacNonceKey,
  HMAC_DATA_SUBJECT_SESSION_KEY: SombraKMSKeyType.HmacDataSubjectSessionKey,
  HMAC_EMPLOYEE_SESSION_KEY: SombraKMSKeyType.HmacEmployeeSessionKey,
  CONSENT_IDENTIFIER_ENCRYPTION_KEY:
    SombraKMSKeyType.ConsentIdentifierEncryptionKey,
  INTERNAL_KEY_HASH: SombraKMSKeyType.InternalKeyHash,
};

export const AsyncJobQueueStatus = makeEnum({ ...QueueStatus });

/**
 * Overload type
 */
export type AsyncJobQueueStatus =
  (typeof AsyncJobQueueStatus)[keyof typeof AsyncJobQueueStatus];

/**
 * All paths for the Sombra job queue
 */
export enum SombraJobQueuePaths {
  Jobs = 'jobs',
  Results = 'results',
}

/**
 * Database column types
 */
export enum DatabaseColumnType {
  // Numeric Data Types
  Integer = 'INTEGER',
  Bigint = 'BIGINT',
  Smallint = 'SMALLINT',
  Smallserial = 'SMALLSERIAL',
  Tinyint = 'TINYINT',
  Float = 'FLOAT',
  Double = 'DOUBLE',
  Decimal = 'DECIMAL',
  Numeric = 'NUMERIC',
  Interval = 'INTERVAL',
  Number = 'NUMBER',
  Long = 'LONG',

  // String (Character) Data Types
  Char = 'CHAR',
  Varchar = 'VARCHAR',
  Text = 'TEXT',
  Tinytext = 'TINYTEXT',
  Mediumtext = 'MEDIUMTEXT',
  Longtext = 'LONGTEXT',
  Nchar = 'NCHAR',
  Nvarchar = 'NVARCHAR',
  Ntext = 'NTEXT',
  Real = 'REAL',
  String = 'STRING',

  // Text Search Data Types
  Tsquery = 'TSQUERY',
  Tsvector = 'TSVECTOR',

  // Date/Time Data Types
  Date = 'DATE',
  Time = 'TIME',
  TimeWithTimezone = 'TIMETZ',
  Datetime = 'DATETIME',
  Smalldatetime = 'SMALLDATETIME',
  Datetimeoffset = 'DATETIMEOFFSET',
  Timestamp = 'TIMESTAMP',
  TimestampWithTimezone = 'TIMESTAMPTZ',
  Year = 'YEAR',
  TimestampWithTimezoneSnowflake = 'TIMESTAMP_NTZ',

  // Boolean Data Types
  Boolean = 'BOOLEAN',
  Bit = 'BIT',
  BitVarying = 'BITVARYING',

  // Binary Data Types
  Blob = 'BLOB',
  Tinyblob = 'TINYBLOB',
  Mediumblob = 'MEDIUMBLOB',
  Longblob = 'LONGBLOB',
  Binary = 'BINARY',
  Varbinary = 'VARBINARY',
  BinaryFloat = 'BINARY_FLOAT',
  BinaryDouble = 'BINARY_DOUBLE',
  Bytea = 'BYTEA',
  Bytes = 'BYTES',

  // UUID / GUID Data Types
  Uuid = 'UUID',
  Guid = 'GUID',
  Fixed = 'FIXED',

  // Spatial Data Types
  Point = 'POINT',
  Linestring = 'LINESTRING',
  Polygon = 'POLYGON',
  Box = 'BOX',
  Circle = 'CIRCLE',
  Line = 'LINE',
  Lseg = 'LSEG',
  Path = 'PATH',

  // JSON and XML Data Types
  Json = 'JSON',
  JsonB = 'JSONB',
  Xml = 'XML',
  Variant = 'VARIANT',
  Object = 'OBJECT',

  // Array and Composite Data Types
  Array = 'ARRAY',
  Composite = 'COMPOSITE',

  // Other Data Types
  Enum = 'ENUM',
  Set = 'SET',
  Cidr = 'CIDR',
  Inet = 'INET',
  Macaddr = 'MACADDR',
  Macaddr8 = 'MACADDR8',
  Serial = 'SERIAL',
  Bigserial = 'BIGSERIAL',
  Money = 'MONEY',
  Smallmoney = 'SMALLMONEY',
  Image = 'IMAGE',
  SqlVariant = 'SQL_VARIANT',
  Cursor = 'CURSOR',
  Table = 'TABLE',
  UserDefined = 'USER_DEFINED',

  // Postgres Specific Data Types
  PgLsn = 'PG_LSN',
  PgSnapshot = 'PG_SNAPSHOT',

  // pyarrow specific data types
  // @see https://arrow.apache.org/docs/python/api/datatypes.html
  Int8 = 'int8',
  Int16 = 'int16',
  Int32 = 'int32',
  Int64 = 'int64',
  UInt8 = 'uint8',
  UInt16 = 'uint16',
  UInt32 = 'uint32',
  UInt64 = 'uint64',
  Float32 = 'float32',
  Float64 = 'float64',
  Time32 = 'time32',
  Time64 = 'time64',
  Date32 = 'date32',
  Date64 = 'date64',
  Duration = 'duration',
  UTF8 = 'utf8',
  LargeString = 'large_utf8',
  BinaryView = 'binary_view',
  StringView = 'string_view',
  Decimal128 = 'decimal128',
  Decimal256 = 'decimal256',
  Bool = 'bool',
  Null = 'null',

  Unknown = 'UNKNOWN',
}

/**
 * Encryption types
 *
 * @deprecated - use ColumnEncryptionType instead
 *
 * TODO - https://transcend.height.app/T-39709 - remove encryption field
 */
export enum EncryptionType {
  Unknown = 'UNKNOWN',
  EncryptedApplicationSide = 'ENCRYPTED_APPLICATION_SIDE',
  EncryptedByTableCreateOption = 'ENCRYPTED_BY_TABLE_CREATE_OPTION',
}

/**
 * Encryption types for database column
 */
export const ColumnEncryptionType = makeEnum({
  EncryptedApplicationSide: 'ENCRYPTED_APPLICATION_SIDE',
  EncryptedByDataAtRest: 'ENCRYPTED_DATA_AT_REST',
});

/** Type override */
export type ColumnEncryptionType =
  (typeof ColumnEncryptionType)[keyof typeof ColumnEncryptionType];

/**
 * Encryption types for database table
 */
export const TableEncryptionType = makeEnum({
  Unencrypted: 'UNENCRYPTED',
  EncryptedApplicationSide: 'ENCRYPTED_APPLICATION_SIDE',
  EncryptedByDataAtRest: 'ENCRYPTED_DATA_AT_REST',
});

/** Type override */
export type TableEncryptionType =
  (typeof TableEncryptionType)[keyof typeof TableEncryptionType];

/**
 * SQL query join types
 */
export enum SQLJoinType {
  Inner = 'INNER',
  Left = 'LEFT',
}

/**
 * Self hosted LLMs that require prompt to be generated
 */
export enum SelfHostedPromptedLLMType {
  /**
   * Structured Discovery CC model on Sombra
   * https://huggingface.co/nvidia/Llama-3.1-Minitron-4B-Depth-Base
   */
  Minitron = 'Llama-3.1-Minitron-4B-Depth-Base',
}

/**
 * SaaS LLMs (from AWS BedRock) that require prompt to be generated
 */
export enum SaaSLLMType {
  /**
   * Structured Discovery CC model on backend
   */
  AnthropicClaude3Haiku = 'anthropic.claude-3-haiku-20240307-v1:0',
}

export const PromptedLLMType = makeEnum({
  ...SelfHostedPromptedLLMType,
  ...SaaSLLMType,
});

/** type overload */
export type PromptedLLMType =
  (typeof PromptedLLMType)[keyof typeof PromptedLLMType];

export const SelfHostedLLMType = makeEnum({
  /** @deprecated - comprehend_it is removed as of v3.0.0 */
  ComprehendIt: 'comprehend_it-base',
  /** Name Entity Recognition model on Sombra */
  GLiNERMultiPII: 'gliner_multi_pii-v1',
  ...SelfHostedPromptedLLMType,
});

/** type overload */
export type SelfHostedLLMType =
  (typeof SelfHostedLLMType)[keyof typeof SelfHostedLLMType];

export const LLMType = makeEnum({ ...SelfHostedLLMType, ...SaaSLLMType });

/** type overload */
export type LLMType = (typeof LLMType)[keyof typeof LLMType];

/**
 * The types of errors that can occur during unstructured data sampling
 */
export const UnstructuredDataSamplingSombraErrors = makeEnum({
  UnsupportedFileTypeAbortSignal: 'Unsupported file type.',
  UnsupportedContentType: 'Unsupported content type.',
  FileTooBigToParseError: 'File is too big to be parsed.',
  UnknownHttpError: 'Unknown HTTP error.',
  FileNotFoundError: 'File not found.',
  CorruptedBufferError:
    'Unable to read file - the file data is possibly corrupted.',
});

/** type overload */
export type UnstructuredDataSamplingSombraErrors =
  (typeof UnstructuredDataSamplingSombraErrors)[keyof typeof UnstructuredDataSamplingSombraErrors];

export const RAW_PYARROW_DATA_TYPE_MAPPING: Record<string, DatabaseColumnType> =
  {
    string: DatabaseColumnType.String,
    int8: DatabaseColumnType.Int64,
    int16: DatabaseColumnType.Int16,
    int32: DatabaseColumnType.Int32,
    int64: DatabaseColumnType.Int64,
    uint8: DatabaseColumnType.UInt8,
    uint16: DatabaseColumnType.UInt16,
    uint32: DatabaseColumnType.UInt32,
    uint64: DatabaseColumnType.UInt64,
    float32: DatabaseColumnType.Float32,
    float64: DatabaseColumnType.Float64,
    time32: DatabaseColumnType.Time32,
    time64: DatabaseColumnType.Time64,
    Date32: DatabaseColumnType.Date32,
    Date64: DatabaseColumnType.Date64,
    Duration: DatabaseColumnType.Duration,
    utf8: DatabaseColumnType.UTF8,
    large_string: DatabaseColumnType.LargeString,
    binary_view: DatabaseColumnType.BinaryView,
    decimal128: DatabaseColumnType.Decimal128,
    decimal256: DatabaseColumnType.Decimal256,
    bool: DatabaseColumnType.Bool,
    timestamp: DatabaseColumnType.Timestamp,
    json: DatabaseColumnType.Json,
    null: DatabaseColumnType.Null,
    double: DatabaseColumnType.Double,
  };
/* eslint-enable max-lines */
