import {
  AssessmentFormTemplateSource,
  AssessmentFormTemplateStatus,
} from '@transcend-io/privacy-types';

import { UserPreview } from '@main/access-control-types';
import { AttributeKeyPreview } from '@main/attribute-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { AssessmentEmailSet } from './assessmentEmailSet';
import {
  AssessmentSection,
  AssessmentSectionInput,
  AssessmentSectionPreview,
  AssessmentSectionRaw,
} from './assessmentSection';
import { AssessmentTrigger, AssessmentTriggerInput } from './assessmentTrigger';
import {
  RetentionSchedule,
  UpdateRetentionScheduleInput,
} from './retentionSchedule';

export const CreateAssessmentFormTemplateRetentionScheduleInput = mkInput({
  name: 'CreateAssessmentFormTemplateRetentionScheduleInput',
  comment:
    'Input for creating the retention schedule for an assessment form template',
  fields: {
    durationDays: UpdateRetentionScheduleInput.fields.durationDays,
    operation: UpdateRetentionScheduleInput.fields.operation,
  },
});

/** Override type */
export type CreateAssessmentFormTemplateRetentionScheduleInput = SchemaToType<
  typeof CreateAssessmentFormTemplateRetentionScheduleInput
>;

export const UpdateAssessmentFormTemplateRetentionScheduleInput = mkInput({
  name: 'UpdateAssessmentFormTemplateRetentionScheduleInput',
  comment:
    'Input for updating the retention schedule for an assessment form template',
  fields: {
    ...CreateAssessmentFormTemplateRetentionScheduleInput.fields,
    updateAssessmentForms: {
      comment:
        'Whether to also update the retention schedule for all assessment forms created from this template',
      type: 'boolean',
    },
  },
});

/** Override type */
export type UpdateAssessmentFormTemplateRetentionScheduleInput = SchemaToType<
  typeof UpdateAssessmentFormTemplateRetentionScheduleInput
>;

export const CreateAssessmentFormTemplateInput = mkInput({
  name: 'CreateAssessmentFormTemplateInput',
  comment: 'Input for creating an Assessment Form Template',
  fields: {
    title: {
      comment: 'The title of the Assessment Form Template',
      type: 'string',
    },
    description: {
      comment: 'The description of the Assessment Form Template',
      type: 'string',
      optional: true,
    },
    status: {
      comment: 'The status of the Assessment Form Template',
      type: { AssessmentFormTemplateStatus },
      // defaults to DRAFT
      optional: true,
    },
    sections: {
      comment: 'The sections belonging to this template',
      type: AssessmentSectionInput,
      optional: true,
      list: true,
    },
    parentId: {
      comment:
        'The id of the parent Assessment Form Template if creating from an existing template',
      modelName: 'assessmentFormTemplate',
      type: 'id',
      optional: true,
    },
    source: {
      comment: 'The method by which the assessment form template was created',
      type: { AssessmentFormTemplateSource },
      optional: true,
    },
    retentionSchedule: {
      comment: 'The retention schedule for the Assessment Form Template',
      type: CreateAssessmentFormTemplateRetentionScheduleInput,
      optional: true,
    },
    assessmentEmailSetId: {
      comment: 'The email template set used for notifications',
      type: 'id',
      modelName: 'assessmentEmailSet',
      optional: true,
    },
    triggers: {
      comment: 'The trigger configurations of the template',
      type: AssessmentTriggerInput,
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type CreateAssessmentFormTemplateInput = SchemaToType<
  typeof CreateAssessmentFormTemplateInput
>;

export const UpdateAssessmentFormTemplateInput = mkInput({
  name: 'UpdateAssessmentFormTemplateInput',
  comment: 'Input for updating an assessment form template',
  fields: {
    id: {
      comment: 'The id of the assessment form template',
      modelName: 'assessmentFormTemplate',
      type: 'id',
    },
    title: {
      ...CreateAssessmentFormTemplateInput.fields.title,
      optional: true,
    },
    description: CreateAssessmentFormTemplateInput.fields.description,
    status: CreateAssessmentFormTemplateInput.fields.status,
    retentionSchedule: {
      ...CreateAssessmentFormTemplateInput.fields.retentionSchedule,
      type: UpdateAssessmentFormTemplateRetentionScheduleInput,
    },
    sections: CreateAssessmentFormTemplateInput.fields.sections,
    isArchived: {
      comment: 'Whether to archive or unarchive this template',
      type: 'boolean',
      optional: true,
    },
    isLocked: {
      comment: 'Whether to lock or unlock this template',
      type: 'boolean',
      optional: true,
    },
    assessmentEmailSetId:
      CreateAssessmentFormTemplateInput.fields.assessmentEmailSetId,
  },
});

/** Override type */
export type UpdateAssessmentFormTemplateInput = SchemaToType<
  typeof UpdateAssessmentFormTemplateInput
>;

export const DeleteAssessmentFormTemplatesInput = mkInput({
  name: 'DeleteAssessmentFormTemplatesInput',
  comment: 'Input for deleting multiple assessment form templates',
  fields: {
    ids: {
      comment: 'The ids of the assessment form templates to delete',
      type: 'id',
      modelName: 'assessmentFormTemplate',
      list: true,
    },
  },
});

/** Override type */
export type DeleteAssessmentFormTemplatesInput = SchemaToType<
  typeof DeleteAssessmentFormTemplatesInput
>;

export const AssessmentFormTemplatePreview = mkType({
  name: 'AssessmentFormTemplatePreview',
  comment:
    'Subset of fields for an editable template from which assessment forms can be created',
  fields: {
    id: {
      comment: 'The id of the assessment form template',
      type: 'id',
      modelName: 'assessmentFormTemplate',
    },
    title: {
      comment: 'The title of the assessment form template',
      type: 'string',
    },
    sections: {
      comment: 'The sections belonging to this template',
      type: AssessmentSectionPreview,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentFormTemplatePreview = SchemaToType<
  typeof AssessmentFormTemplatePreview
>;

export const AssessmentFormTemplateFiltersInput = mkInput({
  name: 'AssessmentFormTemplateFiltersInput',
  comment: 'Inputs for filtering a list of assessment form templates',
  fields: {
    ids: {
      comment: 'The ids of assessment form templates',
      type: 'id',
      modelName: 'assessmentFormTemplate',
      optional: true,
      list: true,
    },
    text: {
      comment: 'The titles or descriptions of assessment form templates',
      optional: true,
      type: 'string',
    },
    creatorIds: {
      comment: 'The IDs of the users who created the assessment form templates',
      optional: true,
      list: true,
      type: 'id',
      modelName: 'user',
    },
    lastEditorIds: {
      comment: 'The IDs of the users who created the assessment form templates',
      optional: true,
      list: true,
      type: 'id',
      modelName: 'user',
    },
    statuses: {
      comment: 'The statuses of the assessment form templates',
      type: { AssessmentFormTemplateStatus },
      optional: true,
      list: true,
    },
    sources: {
      comment: 'The method by which the assessment form template was created',
      type: { AssessmentFormTemplateSource },
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type AssessmentFormTemplateFiltersInput = SchemaToType<
  typeof AssessmentFormTemplateFiltersInput
>;

export const AssessmentFormTemplateRaw = mkType({
  name: 'AssessmentFormTemplateRaw',
  comment:
    'A template for creating assessment forms with questions and answers.',
  fields: {
    ...AssessmentFormTemplatePreview.fields,
    description: {
      comment: 'The description of the Assessment Form Template',
      type: 'string',
    },
    status: {
      comment: 'The status of the assessment',
      type: { AssessmentFormTemplateStatus },
    },
    source: {
      comment: 'The method by which the assessment form template was created',
      type: { AssessmentFormTemplateSource },
    },
    creator: {
      comment: 'The user who created the Assessment Form Template',
      type: UserPreview,
      optional: true,
    },
    lastEditor: {
      comment: 'The user who last edited the Assessment Form Template',
      type: UserPreview,
      optional: true,
    },
    parentId: {
      comment: 'The id of the parent of this Assessment Form Template',
      modelName: 'assessmentFormTemplate',
      type: 'id',
      optional: true,
    },
    isLocked: {
      comment: 'Whether the Assessment Form Template is in a locked status',
      type: 'boolean',
    },
    isArchived: {
      comment: 'Whether the Assessment Form Template is archived',
      type: 'boolean',
    },
    createdAt: {
      comment: 'The date the Assessment Form Template was created',
      type: 'Date',
    },
    updatedAt: {
      comment: 'The date the Assessment Form Template was last updated',
      type: 'Date',
    },
    sections: {
      comment: 'The Assessment sections under this template',
      type: AssessmentSectionRaw,
      list: true,
    },
    attributeKeys: {
      comment:
        'Attribute keys of type assessment that have been associated with this template',
      type: AttributeKeyPreview,
      optional: true,
      list: true,
    },
    retentionSchedule: {
      comment: 'The retention schedule for the Assessment Form Template',
      type: RetentionSchedule,
      optional: true,
    },
    assessmentEmailSet: {
      comment: 'The email template set used for notifications',
      type: AssessmentEmailSet,
    },
    triggers: {
      comment: 'The trigger configured for this template',
      type: AssessmentTrigger,
      list: true,
    },
  },
});

/** Override type */
export type AssessmentFormTemplateRaw = SchemaToType<
  typeof AssessmentFormTemplateRaw
>;

/** Type of the full, parsed AssessmentFormTemplate; raw and full types are helpful when using `buildUseTransformQuery` */
export type AssessmentFormTemplate = Omit<
  AssessmentFormTemplateRaw,
  'sections'
> & {
  /** sections with questions with parsed `displayLogic` */
  sections: AssessmentSection[];
};
