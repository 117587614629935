import { DarkColorOption, LightColorOption } from './types';
/**
 * Mapping from a darker color shade to its respective lighter one, based off our theme in `theme/constants/FIXED_COLORS`
 */
export const SHADE_MAP: {
  [k in keyof DarkColorOption]: keyof LightColorOption;
} = {
  red1: 'red2',
  orange1: 'orange2',
  yellow1: 'yellow2',
  lime1: 'lime2',
  mint1: 'mint2',
  blue1: 'blue2',
  purple1: 'purple2',
  pink1: 'pink2',
  brown1: 'brown2',
  transcendNavy2: 'transcendNavy3',
};
