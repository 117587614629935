import { UserPreview } from '@main/access-control-types';
import { mkInput, mkType, SchemaToType } from '@main/schema-utils';

import { AssessmentLogicInput } from './assessmentLogic';
import { AssessmentTriggerAction, AssessmentTriggerEvent } from './enums';

export const AssessmentTriggerInput = mkInput({
  name: 'AssessmentTriggerInput',
  comment: 'Input for the trigger configuration for an assessment',
  fields: {
    action: {
      comment: 'The action to take',
      type: { AssessmentTriggerAction },
    },
    event: {
      comment: 'The event that causes the action',
      type: { AssessmentTriggerEvent },
    },
    targetTemplateId: {
      comment:
        'The id of the template that should be kicked off by this trigger',
      modelName: 'assessmentFormTemplate',
      type: 'id',
      optional: true,
    },
    targetAssigneeIds: {
      comment: 'The assignees for the target assessment form',
      modelName: 'user',
      type: 'id',
      list: true,
      optional: true,
    },
    logic: {
      comment:
        'The trigger logic to evaluate when deciding whether to take the action',
      type: AssessmentLogicInput,
    },
  },
});

/** Override type */
export type AssessmentTriggerInput = SchemaToType<
  typeof AssessmentTriggerInput
>;
export const AssessmentTrigger = mkType({
  name: 'AssessmentTrigger',
  comment: 'The trigger configured for an assessment template',
  fields: {
    action: {
      comment: 'The action to take',
      type: { AssessmentTriggerAction },
    },
    event: {
      comment: 'The event that causes the action',
      type: { AssessmentTriggerEvent },
    },
    targetTemplateId: {
      comment:
        'The id of the template that should be kicked off by this trigger',
      modelName: 'assessmentFormTemplate',
      type: 'id',
      optional: true,
    },
    targetAssignees: {
      comment: 'The users to be assigned to the target form.',
      type: UserPreview,
      optional: true,
      list: true,
    },
    logic: {
      comment:
        'The trigger logic to evaluate when deciding whether to take the action',
      type: 'string',
    },
  },
});

/** Override type */
export type AssessmentTrigger = SchemaToType<typeof AssessmentTrigger>;
