import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PrivacyCenterTrackingPurposePreview } from '@main/cm-types';
import { PurposeSchema } from '@main/sombra-types';

/**
 * Purposes state
 */
export interface PurposesState {
  /** The privacyCenter purposes configuration */
  configuration?: PrivacyCenterTrackingPurposePreview[];
  /** The user's preferences */
  preferences?: Record<string, PurposeSchema[] | undefined>;
}

export const purposesSlice = createSlice({
  name: 'Purposes',
  initialState: {} as PurposesState,
  reducers: {
    setPrivacyCenterPurposeConfiguration: (
      state,
      {
        payload: configuration,
      }: PayloadAction<PrivacyCenterTrackingPurposePreview[]>,
    ) => ({
      ...state,
      configuration,
    }),
    resetPrivacyCenterPurposeConfiguration: (state) => ({
      ...state,
      configuration: undefined,
    }),
    setPrivacyCenterPurposePreferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** Partition */
        partition: string;
        /** User's preferences */
        preferences: PurposeSchema[];
      }>,
    ) => ({
      ...state,
      preferences: {
        ...state.preferences,
        [payload.partition]: payload.preferences,
      },
    }),
    resetPrivacyCenterPurposePreferences: (state) => ({
      ...state,
      preferences: undefined,
    }),
    resetPrivacyCenterPurposePreferencesForPartition: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** Partition */
        partition: string;
      }>,
    ) => ({
      ...state,
      preferences: {
        ...state.preferences,
        [payload.partition]: undefined,
      },
    }),
  },
});

export const {
  setPrivacyCenterPurposeConfiguration,
  resetPrivacyCenterPurposeConfiguration,
  setPrivacyCenterPurposePreferences,
  resetPrivacyCenterPurposePreferences,
  resetPrivacyCenterPurposePreferencesForPartition,
} = purposesSlice.actions;
