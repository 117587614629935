import { ProcessingPurpose } from '@transcend-io/privacy-types';

import { TeamPreview, UserPreview } from '@main/access-control-types';
import { AttributeValue } from '@main/attribute-types';
import { mkInput, mkOrder, mkType, SchemaToType } from '@main/schema-utils';

import { BusinessEntityPreview } from './businessEntityPreview';
import { Region } from './dataSilo';
import { DiscoveredByDataSiloPreview } from './dataSiloPreview';
import { DataSubject } from './dataSubject';
import {
  Controllership,
  ProcessingActivityOrderField,
  RetentionType,
} from './enums';
import { PurposeSubCategoryPreview } from './processingPurpose';
import { SaaSCategoryBase } from './saaSCategoryBase';
import { VendorPreview } from './vendorPreview';

export const ProcessingActivitiesFiltersInput = mkInput({
  name: 'ProcessingActivitiesFiltersInput',
  comment: 'Filters for processing activities',
  fields: {
    ids: {
      comment: 'The ids of the processing activities',
      type: 'id',
      modelName: 'processingActivity',
      list: true,
      optional: true,
    },
    text: {
      comment: 'Find any processing activities are iLike a text string',
      optional: true,
      type: 'string',
    },
    attributeValueIds: {
      comment: 'The attribute values used to label the processing activities',
      type: 'id',
      modelName: 'attributeValue',
      list: true,
      optional: true,
    },
    businessEntityIds: {
      comment:
        'The ids of the business entities related to the processing activities',
      type: 'id',
      modelName: 'businessEntity',
      list: true,
      optional: true,
    },
    dataSiloIds: {
      comment: 'The ids of the data silos related to the processing activities',
      type: 'id',
      modelName: 'dataSilo',
      list: true,
      optional: true,
    },
    dataSubjectIds: {
      comment: 'The ids of the subjects of the processing activities',
      type: 'id',
      modelName: 'subject',
      list: true,
      optional: true,
    },
    teamIds: {
      comment: 'The ids of the teams that own the processing activities',
      type: 'id',
      modelName: 'team',
      list: true,
      optional: true,
    },
    ownerIds: {
      comment: 'The ids of the users that own the processing activities',
      type: 'id',
      modelName: 'user',
      list: true,
      optional: true,
    },
    purposes: {
      comment: 'Filter by purpose of processing',
      type: { ProcessingPurpose },
      list: true,
      optional: true,
    },
    processingPurposeSubCategoryIds: {
      comment:
        'The ids of the processing purpose sub categories related to the processing activities',
      type: 'id',
      modelName: 'processingPurposeSubCategory',
      list: true,
      optional: true,
    },
    saaSCategoryIds: {
      comment: 'The ids of the saas categories of the processing activities',
      type: 'id',
      modelName: 'saaSCategory',
      list: true,
      optional: true,
    },
    vendorIds: {
      comment: 'The ids of the vendors of the processing activities',
      type: 'id',
      modelName: 'vendor',
      list: true,
      optional: true,
    },
  },
});

/** Override type */
export type ProcessingActivitiesFiltersInput = SchemaToType<
  typeof ProcessingActivitiesFiltersInput
>;

export const ProcessingActivity = mkType({
  name: 'ProcessingActivity',
  comment: 'A processing activity of the organization',
  fields: {
    id: {
      comment: 'The unique ID of the processing activity',
      modelName: 'processingActivity',
      type: 'id',
    },
    title: {
      comment: 'The title of the processing activity',
      type: 'string',
    },
    description: {
      comment: 'The description of the processing activity',
      type: 'string',
    },
    securityMeasureDetails: {
      comment: 'Security measures related to this processing activity',
      type: 'string',
      optional: true,
    },
    controllerships: {
      comment: 'The controllership values assigned to this processingActivity',
      type: { Controllership },
      list: true,
    },
    storageRegions: {
      comment: 'The storage regions associated with this processing activity',
      type: Region,
      list: true,
    },
    transferRegions: {
      comment: 'The transfer regions associated with this processing activity',
      type: Region,
      list: true,
    },
    retentionType: {
      comment: 'The type of retention schedule',
      type: { RetentionType },
    },
    retentionPeriod: {
      comment:
        'The number of days personal data is retained (for stated period retention types)',
      type: 'int',
      optional: true,
    },
    attributeValues: {
      comment: 'The attribute values used to label this processing activity',
      type: AttributeValue,
      list: true,
    },
    businessEntities: {
      comment:
        'The list of business entities related to this processing activity',
      list: true,
      type: BusinessEntityPreview,
    },
    dataSilos: {
      comment: 'The list of data silos related to this processing activity',
      list: true,
      type: DiscoveredByDataSiloPreview,
    },
    subDataPointDataSilos: {
      comment:
        "The list of data silos related to this processing activity's subdatapoints",
      list: true,
      type: DiscoveredByDataSiloPreview,
    },
    dataSubjects: {
      comment: 'The list of data subjects for this processing activity',
      type: DataSubject,
      list: true,
    },
    teams: {
      comment:
        'The list of teams who are responsible for managing this processing activity',
      list: true,
      type: TeamPreview,
    },
    owners: {
      comment:
        'The list of users who are responsible for managing this processing activity',
      list: true,
      type: UserPreview,
    },
    processingPurposeSubCategories: {
      comment:
        'The list of processing purpose sub categories related to this processing activity',
      list: true,
      type: PurposeSubCategoryPreview,
    },
    saaSCategories: {
      comment:
        'The SaaS categories (sometimes called recipient categories) for this processing activity',
      list: true,
      type: SaaSCategoryBase,
    },
    vendors: {
      comment: 'The vendors for this processing activity',
      list: true,
      type: VendorPreview,
    },
  },
});

/** Override type */
export type ProcessingActivity = SchemaToType<typeof ProcessingActivity>;

/** Order for a processingActivities query */
export const ProcessingActivityOrder = mkOrder(
  ProcessingActivity.name,
  ProcessingActivityOrderField,
);

/** Override type */
export type ProcessingActivityOrder = SchemaToType<
  typeof ProcessingActivityOrder
>;

export const DeleteProcessingActivitiesInput = mkInput({
  name: 'DeleteProcessingActivitiesInput',
  comment: 'Input for deleting processing activities',
  fields: {
    ids: {
      comment: 'The list of IDs to delete',
      type: 'id',
      modelName: 'processingActivity',
      list: true,
    },
  },
});

/** Override type */
export type DeleteProcessingActivitiesInput = SchemaToType<
  typeof DeleteProcessingActivitiesInput
>;

export const CreateProcessingActivityInput = mkInput({
  name: 'CreateProcessingActivityInput',
  comment: 'Input for creating a processing activity',
  fields: {
    title: {
      comment: 'The title of the processing activity',
      type: 'string',
    },
    description: {
      comment: 'The description of the processing activity',
      type: 'string',
    },
  },
});

/** Override type */
export type CreateProcessingActivityInput = SchemaToType<
  typeof CreateProcessingActivityInput
>;
