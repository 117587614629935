import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LanguageKey } from '@transcend-io/internationalization';
import type { RequestAction } from '@transcend-io/privacy-types';

/**
 *token login state
 */
export interface TokenLoginState {
  /**
   * When logging in via JWT, we want to re-open the modal for the request that data subject was making
   * after they've gone through the login loop
   */
  selectedAction?: RequestAction;
  /**
   * When logging in via JWT, we want to preserve the selected language
   */
  selectedLocale?: LanguageKey;
  /**
   * The oauth2 state that was randomly generated for security purposes.
   *
   * Before we start the oauth2 handshake, we generate a random trim,
   * save it to local storage and ensure that the string is matched upon
   * redirect. This ensures that the oauth handshake was initiated from our site
   * on thr browser, not someone attempting to redirect to the privacy center manually with requests.
   */
  oAuthState?: string;
}

export const tokenLoginSlice = createSlice({
  name: 'TokenLogin',
  initialState: {
    subjects: [],
  } as TokenLoginState,
  reducers: {
    unsetSelectedAction: (state) => ({
      selectedAction: undefined,
      ...state,
    }),
    setSelectedAction: (
      state,
      { payload }: PayloadAction<TokenLoginState>,
    ) => ({
      ...state,
      ...payload,
    }),
    setOAuthState: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** The oauth2 state that was randomly generated for security purposes */
        oAuthState?: string;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setSelectedAction, setOAuthState, unsetSelectedAction } =
  tokenLoginSlice.actions;
