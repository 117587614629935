import { ColorPalette } from '@main/theme';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Popover } from '../Popover';
import { calculateVisibleItems, createWrappedTag } from './helpers';
import { LeftLabelTag } from './LeftLabelTag';
import { PartialRightTag } from './PartialRightTag';
import { SingleTag } from './SingleTag';
import { DarkColorOption } from './types';
import {
  PopoverButtonContainer,
  PopoverList,
  PopoverListContainer,
  PopoverListItem,
  StyledTagOverflowButton,
} from './wrappers';

export interface OverflowDoubleTagProps {
  /** Main label of the tag (left) */
  leftLabel: string | JSX.Element;
  /** Sub label of the tag (right) */
  rightLabel: string[];
  /** The main color of the tag, used for background or border */
  color: keyof ColorPalette;
  /** The tag variant, either filled or outline */
  variant: 'filled' | 'outline';
  /** style */
  style?: React.CSSProperties;
  /** the icon to appear at the rightmost point in the tag (usually an X) */
  rightIcons?: JSX.Element[];
  /** the icon to appear at the leftmost point in the tag  */
  leftIcon?: JSX.Element;
  /** Overflow type */
  overflowType?: 'wrap' | 'popover';
}

/**
 * The two-part tag component with overflow that provides a main, higher-level label and a more specific, detailed label list to a respective unit.
 * Default instances will be the same shade with lighter on the left and darker on the right, with white text.
 * Outlined versions will have white/no background with uniform border and text color.
 */
export const OverflowDoubleTag: React.FC<OverflowDoubleTagProps> = ({
  leftLabel,
  rightLabel,
  color,
  variant,
  style = {},
  rightIcons,
  leftIcon,
  overflowType = 'popover',
}) => {
  const textRefs = useRef<HTMLSpanElement[] | null[]>([]);
  const [leftLabelRef, setLeftLabelRef] = useState<HTMLSpanElement>();
  const onLeftLabelSetRef = useCallback(
    (el: HTMLSpanElement) => setLeftLabelRef(el),
    [],
  );
  const [joinedLabel, setJoinedLabel] = useState(
    [
      <span key={-1} ref={onLeftLabelSetRef}>
        <LeftLabelTag
          color={color}
          variant={variant}
          label={leftLabel}
          icon={leftIcon}
          style={{ borderRadius: '4px 0 0 4px' }}
        />
      </span>,
    ].concat(
      rightLabel.map((label, i) => (
        <span
          key={i}
          style={{
            visibility: 'hidden',
            position: 'absolute',
          }}
          ref={(el) => {
            textRefs.current[i] = el;
          }}
        >
          <PartialRightTag
            label={label}
            index={i}
            color={color}
            variant={variant}
            icon={rightIcons ? rightIcons[i] : undefined}
            last={i === rightLabel.length - 1}
            style={{}}
          />
        </span>
      )),
    ),
  );
  const maxWidth = 200;
  const [visibleItems, setVisibleItems] = useState(0);
  const [buttonRef, setButtonRef] = useState<HTMLSpanElement>();
  const onButtonRefSet = useCallback(
    (el: HTMLSpanElement) => setButtonRef(el),
    [],
  );

  useLayoutEffect(() => {
    if (overflowType === 'popover' && leftLabelRef && buttonRef) {
      setVisibleItems(
        calculateVisibleItems(leftLabelRef, buttonRef, textRefs, maxWidth),
      );
    } else if (overflowType === 'wrap' && leftLabelRef) {
      createWrappedTag(
        { leftLabelRef, textRefs },
        {
          rightLabel,
          rightIcons,
          color,
          variant,
          leftIcon,
          leftLabel,
        },
        maxWidth,
        {
          setJoinedLabel,
          setVisibleItems,
        },
      );
    }
  }, [buttonRef, leftLabelRef]);

  const allLabels = rightLabel.map((label, id) => (
    <div style={{ width: 'max-content' }} key={id}>
      <SingleTag
        label={label}
        variant={variant}
        color={color as keyof DarkColorOption}
      />
    </div>
  ));

  const PopoverItems = allLabels.slice(-(allLabels.length - visibleItems));

  return (
    <>
      <span
        style={{
          display: overflowType === 'popover' ? 'inline-flex' : 'block',
          ...style,
        }}
      >
        {joinedLabel}
        <Popover
          contents={
            <PopoverListContainer>
              <PopoverList>
                {PopoverItems.map((option, id) => (
                  <PopoverListItem
                    last={id === PopoverItems.length - 1}
                    key={id}
                  >
                    {option}
                  </PopoverListItem>
                ))}
              </PopoverList>
            </PopoverListContainer>
          }
          trigger={['click']}
          placement="top"
          hideArrow
        >
          <PopoverButtonContainer hide={allLabels.length === visibleItems}>
            <span ref={onButtonRefSet}>
              <StyledTagOverflowButton color={color} variant={variant}>
                {`+${allLabels.length - visibleItems}`}
              </StyledTagOverflowButton>
            </span>
          </PopoverButtonContainer>
        </Popover>
      </span>
    </>
  );
};
