/* eslint-disable max-lines */
import * as t from 'io-ts';

import {
  DATABASE_PREFERENCE_PARAMS,
  mkMutation,
  mkQuery,
  PAGINATION_INPUT_PARAMS,
} from '@main/schema-utils';
import {
  CustomFunctionExecuteOverridePayload,
  CustomFunctionExecutionResult,
  CustomFunctionSignPayload,
  DecryptionContext,
  Sombra,
  SombraApiKey,
} from '@main/sombra-types';

import {
  ActionItemRescanInput,
  ActionItemRunLock,
  ActionItemRunLocksInput,
  ActionItemSubscription,
  AddUserInput,
  ApiKey,
  ApiKeyFiltersInput,
  ApiKeyInput,
  ApiKeyOrder,
  ChangePasswordInput,
  ChangePasswordResult,
  ConfiguredSombra,
  CreateActionItemCollectionInput,
  CreateActionItemsInput,
  CreateActionItemSubscriptionsInput,
  CreatedApiKey,
  CreateEmailSenderAddressInput,
  CreateOrUpdatePreferenceInput,
  CurrentEmailSenderAddressesInput,
  CurrentUser,
  CustomFunction,
  CustomFunctionFilterInput,
  DeleteActionItemCollectionsInput,
  DeleteActionItemsInput,
  DeleteActionItemSubscriptionInput,
  DeleteEmailSenderAddressInput,
  Demo,
  DemoInput,
  DestroyUserInput,
  EmailDomain,
  EmailDomainInput,
  EmailSenderAddress,
  GlobalActionItem,
  GlobalActionItemCollection,
  GlobalActionItemCollectionFiltersInput,
  GlobalActionItemFiltersInput,
  LoginDetailsInput,
  LoginInput,
  LoginMethod,
  OnPremiseSombraInput,
  Organization,
  OrgNotificationDestinationAllowList,
  ParentTeamFiltersInput,
  PreferenceFiltersInput,
  PreferenceRaw,
  QueryActionItemSubscriptionInput,
  RemoveUserInput,
  RequestNewDataSiloTokenInput,
  ResetPasswordInput,
  RunCustomFunctionInput,
  Scope,
  ScopeFiltersInput,
  ScopeOrder,
  SelfServeSignupInput,
  SendTestEmailInput,
  SendTestEmailResponse,
  SendUserInviteEmailInput,
  SetOrgNotificationDestinationsInput,
  SignupCode,
  SignupCodeInput,
  SlackUserMetadata,
  SlackWorkspaceMetadata,
  SombraApiKeyEntry,
  SombraApiKeysFilterInput,
  SombraChangelogEntry,
  SombraFilterInput,
  SombraOrder,
  SsoProvider,
  SsoProviderInput,
  Team,
  TeamFiltersInput,
  TeamInput,
  TeamOrder,
  TeamPreview,
  TokenInput,
  UpdateActionItemCollectionInput,
  UpdateActionItemsInput,
  UpdateApiKeyInput,
  UpdateCurrentUserInput,
  UpdateEmailSenderAddressInput,
  UpdateOrganizationInput,
  UpdatePasswordInput,
  UpdateScopeInput,
  UpdateSombraInput,
  UpdateTeamInput,
  UpdateUserInput,
  User,
  UserFiltersInput,
  UserOrder,
} from './schema';

export const globalActionItems = mkQuery({
  name: 'globalActionItems',
  comment: 'Paginated list of all new (global) action items',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: GlobalActionItemFiltersInput,
  },
  response: {
    nodes: GlobalActionItem.list(),
    totalCount: 'int',
    unrolledCount: 'int',
  },
});

export const updateActionItems = mkMutation({
  name: 'updateActionItems',
  comment: 'Bulk update the metadata for one or more existing action items',
  params: {
    input: UpdateActionItemsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const actionItemSubscriptions = mkQuery({
  name: 'actionItemSubscriptions',
  comment: 'Get a users action item subscriptions',
  params: {
    input: QueryActionItemSubscriptionInput,
  },
  response: ActionItemSubscription.list(),
});

export const createActionItemSubscriptions = mkMutation({
  name: 'createActionItemSubscriptions',
  comment: 'Subscribes a user to notifications for a list of action item types',
  params: {
    input: CreateActionItemSubscriptionsInput,
  },
  response: {
    actionItemSubscriptions: ActionItemSubscription.list(),
  },
});

export const deleteActionItemSubscription = mkMutation({
  name: 'deleteActionItemSubscription',
  comment:
    'Unsubscribes a user from a specific notification or all action item notifications',
  params: {
    input: DeleteActionItemSubscriptionInput,
  },
  response: {
    success: 'boolean',
  },
});

export const actionItemRunLocks = mkQuery({
  name: 'actionItemRunLocks',
  comment: 'Action item run locks',
  params: {
    input: ActionItemRunLocksInput,
  },
  response: {
    nodes: ActionItemRunLock.list(),
    now: 'string',
  },
});

export const actionItemRescans = mkMutation({
  name: 'actionItemRescans',
  comment: 'Action item rescans',
  params: {
    input: ActionItemRescanInput,
  },
  response: {
    success: 'boolean',
  },
});

export const logout = mkMutation({
  name: 'logout',
  comment: 'Logout of the admin dashboard',
  params: {},
  response: {
    success: 'boolean',
  },
});

export const requestDemo = mkMutation({
  name: 'requestDemo',
  comment: `Request a demo to our backend.
This is not a real demo request and is used solely for testing.`,
  params: {
    input: DemoInput,
  },
  response: {
    demo: Demo,
  },
});

export const teams = mkQuery({
  name: 'teams',
  comment: 'The groupings of users within the organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: TeamFiltersInput.optional(),
    orderBy: TeamOrder,
  },
  response: {
    nodes: Team.list(),
    totalCount: 'int',
  },
});

export const parentOrganizationTeams = mkQuery({
  name: 'parentOrganizationTeams',
  comment:
    'The list of teams that can be connected from in the parent organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ParentTeamFiltersInput.optional(),
    orderBy: TeamOrder,
  },
  response: {
    nodes: TeamPreview.list(),
    totalCount: 'int',
  },
});

export const createTeam = mkMutation({
  name: 'createTeam',
  comment: 'Add a new team to the organization',
  params: {
    input: TeamInput,
  },
  response: {
    team: Team,
  },
});

export const deleteTeam = mkMutation({
  name: 'deleteTeam',
  comment: 'Remove a team from the organization',
  params: {
    id: {
      type: 'id',
      modelName: 'team',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const updateTeam = mkMutation({
  name: 'updateTeam',
  comment: 'Updates the attributes of a team',
  params: {
    input: UpdateTeamInput,
  },
  response: {
    team: Team,
  },
});

export const addUser = mkMutation({
  name: 'addUser',
  comment: 'Adds a user to your organization',
  params: {
    input: AddUserInput,
  },
  response: {
    user: User,
  },
});

export const sendUserInviteEmail = mkMutation({
  name: 'sendUserInviteEmail',
  comment: 'Sends the email to invite a user to your organization',
  params: {
    input: SendUserInviteEmailInput,
  },
  response: {
    success: 'boolean',
  },
});

export const destroyAccount = mkMutation({
  name: 'destroyAccount',
  comment: "Destroy the current user's account",
  params: {
    input: DestroyUserInput,
  },
  response: {
    success: 'boolean',
  },
});

export const testSombraEmployeeAuthentication = mkMutation({
  name: 'testSombraEmployeeAuthentication',
  comment: 'Test employee authentication for a specific sombra gateway',
  params: {
    id: {
      type: 'id',
      modelName: 'sombra',
    },
    dhEncrypted: {
      underlyingType: t.type({}),
      type: 'string',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const testSombraGatewayConnection = mkMutation({
  name: 'testSombraGatewayConnection',
  comment: 'Test a basic connection to a sombra gateway',
  params: {
    id: {
      type: 'id',
      modelName: 'sombra',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const testIntegrationsSombraConnectivity = mkMutation({
  name: 'testIntegrationsSombraConnectivity',
  comment:
    'Test a basic connection from an integrations lambda to a Sombra gateway',
  params: {
    id: {
      type: 'id',
      modelName: 'sombra',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const revealRootSombraSecret = mkMutation({
  name: 'revealRootSombraSecret',
  comment:
    'Reveal the root sombra secret, for the purposes of migrating the gateway on-premise',
  params: {
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const generateConsentIdentifierEncryptionKey = mkMutation({
  name: 'generateConsentIdentifierEncryptionKey',
  comment: 'Create a consent identifier encryption key',
  params: {
    dhEncrypted: {
      type: 'string',
      // Empty, as we need the DH channel only to return information.
      underlyingType: t.type({}),
    },
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const removeUser = mkMutation({
  name: 'removeUser',
  comment: 'Remove a user from the organization',
  params: {
    id: {
      type: 'id',
      modelName: 'user',
      isOptional: true,
    },
    input: RemoveUserInput.optional(),
  },
  response: {
    success: 'boolean',
  },
});

export const createOnPremiseSombra = mkMutation({
  name: 'createOnPremiseSombra',
  comment: 'Create a new on-premise sombra gateway instance',
  params: {
    input: OnPremiseSombraInput,
  },
  response: {
    sombra: Sombra,
  },
});

export const issueSombraApiKey = mkMutation({
  name: 'issueSombraApiKey',
  comment: 'Create an API key to use with Sombra',
  params: {
    id: {
      modelName: 'sombra',
      type: 'id',
      comment: 'Sombra ID to associate the API key with',
    },
  },
  response: {
    sombraApiKey: SombraApiKey,
  },
});

export const changePrimarySombra = mkMutation({
  name: 'changePrimarySombra',
  comment: 'Change the primary sombra gateway',
  params: {
    id: {
      type: 'id',
      modelName: 'sombra',
    },
  },
  response: {
    sombra: Sombra,
  },
});

export const updateSombra = mkMutation({
  name: 'updateSombra',
  comment: 'Update a sombra gateway',
  params: {
    id: {
      type: 'id',
      modelName: 'sombra',
    },
    input: UpdateSombraInput,
  },
  response: {
    sombra: ConfiguredSombra,
  },
});

export const removeSombra = mkMutation({
  name: 'removeSombra',
  comment: 'Remove a sombra instance',
  params: {
    id: {
      type: 'id',
      modelName: 'sombra',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const updateScope = mkMutation({
  name: 'updateScope',
  comment: 'Update the associations of a scope',
  params: {
    input: UpdateScopeInput,
  },
  response: {
    scope: Scope,
  },
});

export const createApiKey = mkMutation({
  name: 'createApiKey',
  comment: 'Create a new global organization API key',
  params: {
    input: ApiKeyInput,
  },
  response: {
    apiKey: CreatedApiKey,
  },
});

export const sombras = mkQuery({
  name: 'sombras',
  comment: 'List the sombras in an organization',
  params: {
    filterBy: SombraFilterInput.optional(),
    orderBy: SombraOrder,
  },
  response: ConfiguredSombra.list(),
});

export const sombraChangelog = mkQuery({
  name: 'sombraChangelog',
  comment: 'List out the sombra changelog',
  params: {},
  response: SombraChangelogEntry.list(),
});

export const sombraApiKeys = mkQuery({
  name: 'sombraApiKeys',
  comment: 'List out the sombra API keys',
  params: {
    filterBy: SombraApiKeysFilterInput.optional(),
    id: {
      type: 'id',
      modelName: 'sombra',
      description: 'Optional Sombra ID to list API keys for',
      isOptional: true,
    },
  },
  response: SombraApiKeyEntry.list(),
});

export const expireSombraApiKey = mkMutation({
  name: 'expireSombraApiKey',
  comment: 'Expires a Sombra API key',
  params: {
    id: {
      type: 'id',
      modelName: 'sombraApiKey',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const deleteApiKey = mkMutation({
  name: 'deleteApiKey',
  comment: 'Deletes an existing API key',
  params: {
    id: {
      type: 'id',
      modelName: 'apiKey',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const updateApiKey = mkMutation({
  name: 'updateApiKey',
  comment: 'Updates an existing API key',
  params: {
    input: UpdateApiKeyInput,
  },
  response: {
    apiKey: ApiKey,
  },
});

export const signupCode = mkQuery({
  name: 'signupCode',
  comment: 'Lookup a signup code',
  params: { input: SignupCodeInput },
  response: SignupCode,
});

export const updateSsoProvider = mkMutation({
  name: 'updateSsoProvider',
  comment: 'Update the SSO account for the organization',
  params: {
    input: SsoProviderInput,
    dhEncrypted: {
      type: 'string',
      // requires a dh channel to ensure that a user initiated the update
      // TODO: https://transcend.height.app/T-6572 - make updates through here
      underlyingType: t.type({}),
    },
  },
  response: {
    ssoProvider: SsoProvider,
  },
});

export const ssoProvider = mkQuery({
  name: 'ssoProvider',
  comment: 'The SSO information for the organization',
  params: {},
  response: SsoProvider.optional(),
});

export const organization = mkQuery({
  name: 'organization',
  comment: 'The organization associated with the current user or API key',
  params: {},
  response: Organization,
});

export const updateOrganizationInfo = mkMutation({
  name: 'updateOrganizationInfo',
  comment: 'Update the global organization information',
  params: {
    input: UpdateOrganizationInput,
  },
  response: {
    organization: Organization,
  },
});

export const selfServeSignup = mkMutation({
  name: 'selfServeSignup',
  comment:
    'Signs up a completely new user and organization via onboarding wizard',
  params: {
    input: SelfServeSignupInput,
  },
  response: {
    user: CurrentUser,
  },
});

export const determineLoginMethod = mkMutation({
  name: 'determineLoginMethod',
  comment: 'Determine how to login an email address',
  params: {
    input: LoginDetailsInput,
  },
  response: {
    loginMethod: LoginMethod,
  },
});

export const invalidateUserEmail = mkMutation({
  name: 'invalidateUserEmail',
  comment:
    'Invalidate an email token that was sent for onboarding purposes to the wrong individual. This is the "not you" link.',
  params: {
    input: TokenInput,
  },
  response: {
    invalidated: 'boolean',
  },
});

export const updateUserProfile = mkMutation({
  name: 'updateUserProfile',
  comment: 'Update the profile of the currently logged in user',
  params: {
    input: UpdateCurrentUserInput,
  },
  response: {
    user: CurrentUser,
  },
});

export const user = mkQuery({
  name: 'user',
  comment: 'The logger in user',
  params: {},
  response: CurrentUser.optional(),
});

export const googleSiteKey = mkQuery({
  name: 'googleSiteKey',
  comment: 'The google ReCaptcha site key to use',
  params: {},
  response: {
    key: 'string',
  },
});

export const users = mkQuery({
  name: 'users',
  comment: 'The users in the organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: UserFiltersInput.optional(),
    orderBy: UserOrder,
  },
  response: {
    nodes: User.list(),
    totalCount: 'int',
  },
});

export const apiKey = mkQuery({
  name: 'apiKey',
  comment: 'Grab an API key',
  params: {
    id: {
      modelName: 'apiKey',
      type: 'id',
    },
  },
  response: ApiKey,
});

export const apiKeys = mkQuery({
  name: 'apiKeys',
  comment: 'The API keys in the organization',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    ...DATABASE_PREFERENCE_PARAMS,
    filterBy: ApiKeyFiltersInput.optional(),
    orderBy: ApiKeyOrder,
  },
  response: {
    nodes: ApiKey.list(),
    totalCount: 'int',
  },
});

export const scopes = mkQuery({
  name: 'scopes',
  comment: 'All scopes that can be assigned to users or teams',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: ScopeFiltersInput.optional(),
    orderBy: ScopeOrder,
  },
  response: {
    nodes: Scope.list(),
    totalCount: 'int',
  },
});

export const checkResetPasswordToken = mkQuery({
  name: 'checkResetPasswordToken',
  comment: 'Check whether a reset token is still valid',
  params: {
    input: TokenInput,
  },
  response: ChangePasswordResult,
});

export const resetUserPassword = mkMutation({
  name: 'resetUserPassword',
  comment: 'Reset a password from a logged out view',
  params: {
    input: ResetPasswordInput,
  },
  response: {
    sent: 'boolean',
  },
});

export const updatePassword = mkMutation({
  name: 'updatePassword',
  comment: "Updates a user's password when they are logged in",
  params: {
    input: UpdatePasswordInput,
  },
  response: {
    success: 'boolean',
  },
});

export const updateUser = mkMutation({
  name: 'updateUser',
  comment: 'Update a user in the organization',
  params: {
    input: UpdateUserInput,
  },
  response: {
    user: User,
  },
});

export const assumeRole = mkMutation({
  name: 'assumeRole',
  comment: 'Assume a role into another organization',
  params: {
    id: {
      modelName: 'user',
      type: 'id',
    },
    publicKey: 'string',
  },
  response: {
    user: CurrentUser,
    decryptionContext: DecryptionContext.optional(),
  },
});

export const loginToSombraWithToken = mkMutation({
  name: 'loginToSombraWithToken',
  comment:
    'Authenticate to sombra with a token, and get back a decryption context',
  params: {
    id: {
      modelName: 'sombra',
      type: 'id',
    },
    publicKey: 'string',
    input: TokenInput,
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const login = mkMutation({
  name: 'login',
  comment: 'Log into the admin dashboard',
  params: {
    input: LoginInput,
    publicKey: 'string',
  },
  response: {
    user: CurrentUser,
    decryptionContext: DecryptionContext.optional(),
  },
});

export const changePassword = mkMutation({
  name: 'changePassword',
  comment:
    "Change the user's password from a logged out view, via a reset token",
  params: {
    input: ChangePasswordInput,
    publicKey: 'string',
  },
  response: {
    user: CurrentUser,
    decryptionContext: DecryptionContext.optional(),
  },
});

export const currentEmailDomains = mkQuery({
  name: 'currentEmailDomains',
  comment: 'List the email domains for the current org',
  params: {},
  response: EmailDomain.list(),
});

export const currentEmailSenderAddresses = mkQuery({
  name: 'currentEmailSenderAddresses',
  comment: 'List the email sender addresses for a privacy center',
  params: {
    input: CurrentEmailSenderAddressesInput,
  },
  response: { nodes: EmailSenderAddress.list() },
});

export const updateEmailSenderAddress = mkMutation({
  name: 'updateEmailSenderAddress',
  comment: 'Update the email sender address',
  params: {
    input: UpdateEmailSenderAddressInput,
  },
  response: { address: EmailSenderAddress },
});

export const createEmailSenderAddress = mkMutation({
  name: 'createEmailSenderAddress',
  comment: 'Create an email sender address',
  params: {
    input: CreateEmailSenderAddressInput,
  },
  response: { address: EmailSenderAddress },
});

export const deleteEmailSenderAddress = mkMutation({
  name: 'deleteEmailSenderAddress',
  comment: 'Delete an email sender address',
  params: {
    input: DeleteEmailSenderAddressInput,
  },
  response: { success: 'boolean' },
});

export const addEmailDomain = mkMutation({
  name: 'addEmailDomain',
  comment: 'Add an email domain to the current org',
  params: { input: EmailDomainInput },
  response: { domain: EmailDomain },
});

export const deleteEmailDomain = mkMutation({
  name: 'deleteEmailDomain',
  comment: 'Delete an email domain from the current org',
  params: { input: EmailDomainInput },
  response: { success: 'boolean' },
});

export const duplicateApiKey = mkMutation({
  name: 'duplicateApiKey',
  comment: 'Duplicate an existing API key with a fresh secret',
  params: {
    id: {
      type: 'id',
      modelName: 'apiKey',
    },
  },
  response: { apiKey: CreatedApiKey },
});

export const requestNewDataSiloToken = mkMutation({
  name: 'requestNewDataSiloToken',
  comment: 'Request a new data silo token be sent to the configured email',
  params: {
    input: RequestNewDataSiloTokenInput,
  },
  response: {
    success: 'boolean',
  },
});

export const initiateSitescan = mkMutation({
  name: 'initiateSitescan',
  comment: 'Starts a sitescan',
  params: {},
  response: {
    success: 'boolean',
  },
});

export const setOrgNotificationDestinationAllowList = mkMutation({
  name: 'setOrgNotificationDestinationAllowList',
  comment:
    'Sets allow-list of destinations that notifications can be sent to e.g. slack',
  params: {
    input: SetOrgNotificationDestinationsInput,
  },
  response: {
    orgNotificationDestinationAllowList: OrgNotificationDestinationAllowList,
  },
});

export const createOrUpdateOrganizationPreference = mkMutation({
  name: 'createOrUpdateOrganizationPreference',
  comment: 'Create or update a preference for an organization',
  params: {
    input: CreateOrUpdatePreferenceInput,
  },
  response: {
    preference: PreferenceRaw,
  },
});

export const createOrUpdateUserPreference = mkMutation({
  name: 'createOrUpdateUserPreference',
  comment: 'Create or update a preference for a user',
  params: {
    input: CreateOrUpdatePreferenceInput,
  },
  response: {
    preference: PreferenceRaw,
  },
});

export const preference = mkQuery({
  name: 'preference',
  comment: 'Get the preferences for an organization or user',
  params: {
    filterBy: PreferenceFiltersInput,
  },
  response: {
    preference: PreferenceRaw.optional(),
  },
});

export const globalActionItemCollections = mkQuery({
  name: 'globalActionItemCollections',
  comment:
    'Paginated list of all action item collections for the specified location',
  params: {
    filterBy: GlobalActionItemCollectionFiltersInput,
  },
  response: {
    nodes: GlobalActionItemCollection.list(),
  },
});

export const updateActionItemCollection = mkMutation({
  name: 'updateActionItemCollection',
  comment: 'Update an action item collection',
  params: {
    input: UpdateActionItemCollectionInput,
  },
  response: {
    success: 'boolean',
  },
});

export const createActionItemCollection = mkMutation({
  name: 'createActionItemCollection',
  comment: 'Create an action item collection',
  params: {
    input: CreateActionItemCollectionInput,
  },
  response: {
    created: GlobalActionItemCollection,
  },
});

export const createActionItems = mkMutation({
  name: 'createActionItems',
  comment: 'Create action items',
  params: {
    input: CreateActionItemsInput.list(),
  },
  response: {
    success: 'boolean',
  },
});

export const deleteActionItems = mkMutation({
  name: 'deleteActionItems',
  comment: 'Delete action items',
  params: {
    input: DeleteActionItemsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const deleteActionItemCollections = mkMutation({
  name: 'deleteActionItemCollections',
  comment: 'Delete action item collections',
  params: {
    input: DeleteActionItemCollectionsInput,
  },
  response: {
    success: 'boolean',
  },
});

export const revealConsentIdentifierEncryptionKey = mkMutation({
  name: 'revealConsentIdentifierEncryptionKey',
  comment:
    'Reveal the consent identifier encryption key, for customers to copy and add to their backend for managed consent',
  params: {
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
  },
  response: {
    decryptionContext: DecryptionContext,
  },
});

export const disableUserAccount = mkMutation({
  name: 'disableUserAccount',
  comment: 'Disable a user account',
  params: {
    id: {
      comment: 'The ID of the user to disable',
      type: 'id',
      modelName: 'user',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const enableUserAccount = mkMutation({
  name: 'enableUserAccount',
  comment: 'Enable a user account',
  params: {
    id: {
      comment: 'The ID of the user to enable',
      type: 'id',
      modelName: 'user',
    },
  },
  response: {
    success: 'boolean',
  },
});

export const slackWorkspaceMetadata = mkQuery({
  name: 'slackWorkspaceMetadata',
  comment: 'Retrieve metadata about a Slack instance from an OAuth connection',
  params: {},
  response: SlackWorkspaceMetadata,
});

export const slackUserMetadata = mkQuery({
  name: 'slackUserMetadata',
  comment: 'Retrieve metadata about a Slack user from an OAuth connection',
  params: {},
  response: SlackUserMetadata,
});

export const sendTestEmail = mkMutation({
  name: 'sendTestEmail',
  comment:
    'Send a test email through sombra to verify everything is setup correctly',
  params: {
    input: SendTestEmailInput,
  },
  response: { response: SendTestEmailResponse },
});

export const isUserAuthenticated = mkMutation({
  name: 'isUserAuthenticated',
  comment:
    'Check if the currently logged in user has a valid sombra session. ' +
    'If session is invalid but transcend employee authentication is enabled, a new sombra session will be returned.',
  params: {
    dhEncrypted: {
      type: 'string',
      underlyingType: t.type({}),
    },
    publicKey: 'string',
  },
  response: {
    decryptionContext: DecryptionContext.optional(),
    success: 'boolean',
  },
});

export const customFunctions = mkQuery({
  name: 'customFunctions',
  comment: 'List custom functions',
  params: {
    ...PAGINATION_INPUT_PARAMS,
    filterBy: CustomFunctionFilterInput,
  },
  response: {
    nodes: CustomFunction.list(),
    totalCount: 'int',
  },
});

export const updateCustomFunction = mkMutation({
  name: 'updateCustomFunction',
  comment: "Update or Create a Custom Function's code or context",
  params: {
    id: {
      type: 'id',
      modelName: 'customFunction',
      isOptional: true,
    },
    dhEncrypted: {
      type: 'string',
      underlyingType: CustomFunctionSignPayload,
    },
  },
  response: {
    customFunction: CustomFunction,
    success: 'boolean',
  },
});

export const runCustomFunction = mkMutation({
  name: 'runCustomFunction',
  comment: 'Run a Custom Function',
  params: {
    input: RunCustomFunctionInput,
    dhEncrypted: {
      comment:
        'If we wish to override parts of the custom function context. Used for testing and development of functions',
      isOptional: true,
      type: 'string',
      underlyingType: CustomFunctionExecuteOverridePayload,
    },
  },
  response: {
    result: CustomFunctionExecutionResult,
  },
});
/* eslint-enable max-lines */
