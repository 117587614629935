import styled from 'styled-components';

import { ColorPalette } from '@main/theme';

import { SHADE_MAP } from './constants';

export const StyledTagSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
`;

export const StyledTagOverflowButton = styled.button<{
  /** Color of the tag */
  color: keyof ColorPalette;
  /** Variant of the tag */
  variant: string;
}>`
  border: none;
  border-radius: 0 4px 4px 0;
  font-size: 12px;
  font-weight: 600;
  min-width: 27px;
  border-style: solid;
  border-width: 1px;
  border-left: none;
  padding: 4px 8px;
  border-top: 1px solid ${({ theme, color }) => theme.colors[color]};
  border-right: 1px solid ${({ theme, color }) => theme.colors[color]};
  border-bottom: 1px solid ${({ theme, color }) => theme.colors[color]};
  color: ${({ theme, color, variant }) =>
    variant === 'outline' ? theme.colors[color] : theme.colors.white};
  background: ${({ theme, color, variant }) =>
    variant === 'outline' ? 'unset' : theme.colors[color]};
`;

export const PopoverListItem = styled.li<{
  /** Whether the item is last in the list */
  last: boolean;
}>`
  overflow: hidden;
  max-width: 250px;
  margin-bottom: ${({ last }) => (last ? '0' : '4px')};
`;

export const PopoverList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const PopoverListContainer = styled.div`
  padding: 4;
  max-width: 300px;
  overflow: 'auto';
`;

export const PopoverButtonContainer = styled.div<{
  /** Whether the popover button should be hidden */
  hide: boolean;
}>`
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
  position: ${({ hide }) => (hide ? 'absolute' : '')};
`;

export const LeftLabelTagContainer = styled(StyledTagSection)<{
  /** The variant of the tag */
  variant: string;
  /** The color of the tag */
  color: keyof ColorPalette;
}>`
  color: ${({ theme, color, variant }) =>
    variant === 'outline' ? theme.colors[color] : theme.colors.white};
  background: ${({ theme, color, variant }) =>
    variant === 'outline' ? 'unset' : theme.colors[SHADE_MAP[color]]};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, color, variant }) =>
    variant === 'outline'
      ? theme.colors[color]
      : theme.colors[SHADE_MAP[color]]};
`;

export const PartialRightTagContainer = styled(StyledTagSection)<{
  /** The variant of the tag */
  variant: string;
  /** The color of the tag */
  color: keyof ColorPalette;
  /** Whether the tag is the last in the list */
  last: boolean;
}>`
  color: ${({ theme, color, variant }) =>
    variant === 'outline' ? theme.colors[color] : theme.colors.white};
  background: ${({ theme, color, variant }) =>
    variant === 'outline' ? 'unset' : theme.colors[color]};
  border-width: 1px;
  border-style: solid;
  border-left: none;
  border-top: 1px solid ${({ theme, color }) => theme.colors[color]};
  border-right: 1px solid ${({ theme, color }) => theme.colors[color]};
  border-bottom: 1px solid ${({ theme, color }) => theme.colors[color]};
  border-right-color: ${({ theme, color, last }) =>
    last ? theme.colors[color] : theme.colors[SHADE_MAP[color]]};
  border-radius: ${({ last }) => (last ? '0 4px 4px 0' : '')};
`;
