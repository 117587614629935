import { makeEnum } from '@transcend-io/type-utils';

/**
 * Privacy center intl messages
 */
export const PrivacyCenterIntlMessageType = makeEnum({
  // TODO: https://transcend.height.app/T-38900 - remove / migrate replaced message types after we migrate to workflows
  /** The text to show on the confirmation modal for the DSR type */
  ActionSecondaryDescription: 'ACTION_SECONDARY_DESCRIPTION',
  /** The display title of the DSR type */
  ActionSubjectTitle: 'ACTION_SUBJECT_TITLE',
  /** The primary description of the DSR type */
  ActionDescription: 'ACTION_DESCRIPTION',
  /** Label for a form item to display with an action */
  ActionFormItem: 'ACTION_FORM_ITEM',
  /** Instructions to display in the modal to instruct the data subject how to log in */
  DataSubjectLoginInstructions: 'DATA_SUBJECT_LOGIN_INSTRUCTIONS',
  /** Instructions to display in the modal to instruct the data subject how to log in a different way */
  DataSubjectDisabledInstructions: 'DATA_SUBJECT_DISABLED_INSTRUCTIONS',
  /** The display title of the identifier */
  IdentifierTitle: 'IDENTIFIER_TITLE',
  /** The display description of the identifier */
  IdentifierDescription: 'IDENTIFIER_DESCRIPTION',
  /** The display title of the data subject */
  DataSubjectTitle: 'DATA_SUBJECT_TITLE',
  /** The redirect URL for a data subject login */
  DataSubjectRedirectUrl: 'DATA_SUBJECT_REDIRECT_URL',
  /** The title of a policy */
  PolicyTitle: 'POLICY_TITLE',
  /** The HTML content of a policy */
  PolicyContent: 'POLICY_CONTENT',
  /** Alt tag for an image */
  AssetFileAlt: 'ASSET_FILE_ALT',
  /** Subject of an email */
  TemplateSubject: 'TEMPLATE_SUBJECT',
  /** Email HTML template */
  TemplateTemplate: 'TEMPLATE_TEMPLATE',
  /** Title of a data collection */
  DataCollectionTitle: 'DATA_COLLECTION_TITLE',
  /**
   * Title of a data category
   * TODO: https://transcend.height.app/T-42677 - drop
   */
  DataCategoryTitle: 'DATA_CATEGORY_TITLE',
  /**
   * Title of a data application
   * TODO: https://transcend.height.app/T-42677 - drop
   */
  DataApplicationTitle: 'DATA_APPLICATION_TITLE',
  /**
   * Description of a data applicatio
   * TODO: https://transcend.height.app/T-42677 - drop
   */
  DataApplicationDescription: 'DATA_APPLICATION_DESCRIPTION',
  /**
   * Description of a data collection
   * TODO: https://transcend.height.app/T-42677 - drop
   */
  DataCollectionDescription: 'DATA_COLLECTION_DESCRIPTION',
  /** Title of a datapoint */
  DataPointTitle: 'DATA_POINT_TITLE',
  /** Description of a datapoint */
  DataPointDescription: 'DATA_POINT_DESCRIPTION',
  /** One of the hard coded messages defined in a messages.ts file in the privacy-center package */
  LooseMessage: 'LOOSE_MESSAGE',
  /** The link back to the main website for a privacy center */
  PrivacyCenterHomeUrl: 'PRIVACY_CENTER_HOME_URL',
  /** The title of the workflow */
  WorkflowTitle: 'WORKFLOW_TITLE',
  /** The subtitle of the workflow */
  WorkflowSubtitle: 'WORKFLOW_SUBTITLE',
  /** The description of the workflow */
  WorkflowDescription: 'WORKFLOW_DESCRIPTION',
  /** A message for purpose title */
  ConsentPurposeTitle: 'CONSENT_PURPOSE_TITLE',
  /** A message for Preference Topic title */
  PreferenceTopicTitle: 'PREFERENCE_TOPIC_TITLE',
  /** A message for Preference Topic description */
  PreferenceTopicDescription: 'PREFERENCE_TOPIC_DESCRIPTION',
  /** A message for Preference Option title */
  PreferenceOptionTitle: 'PREFERENCE_OPTION_TITLE',
});

/** Overrides type */
export type PrivacyCenterIntlMessageType =
  (typeof PrivacyCenterIntlMessageType)[keyof typeof PrivacyCenterIntlMessageType];

/**
 * Consent manager intl messages
 */
export const ConsentManagerIntlMessageType = makeEnum({
  /** A message shown in the consent manager */
  ConsentManagerMessage: 'CONSENT_MANAGER_MESSAGE',
  /** A message for purpose titles in the consent manager */
  ConsentPurposeTitle: 'CONSENT_PURPOSE_TITLE',
  /** A message for purpose descriptions in the consent manager */
  ConsentPurposeDescription: 'CONSENT_PURPOSE_DESCRIPTION',
  /** Messages for the TCF UI view */
  ConsentManagerTcfMessage: 'CONSENT_MANAGER_TCF_MESSAGE',
});

/** Overrides type */
export type ConsentManagerIntlMessageType =
  (typeof ConsentManagerIntlMessageType)[keyof typeof ConsentManagerIntlMessageType];

/**
 * The different types of messages that can be translated
 * throughout the product
 */
export const IntlMessageType = makeEnum({
  ...PrivacyCenterIntlMessageType,
  ...ConsentManagerIntlMessageType,
});

/** Overrides type */
export type IntlMessageType =
  (typeof IntlMessageType)[keyof typeof IntlMessageType];
