import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { PrivacyCenter, PrivacyCenterAuthContext } from '@main/pc-types';
import {
  SombraCoreIdentifier,
  SombraDataSubjectAuthMethod,
  SombraProfile,
} from '@main/sombra-types';

/**
 * The child organization sombra session profile
 */
export interface ChildSessionProfile {
  /** The secret established between the data subject and the organization's authentication server */
  authSecret?: string;
  /** the organization of the child organization */
  organizationId: string;
  /** The session profile, when provided indicates there is a logged-in user */
  sessionProfile: SombraProfile;
  /** The core identifier that was returned by sombra */
  coreIdentifier: SombraCoreIdentifier;
}

/**
 * the privacy center state
 */
export interface PrivacyCenterState extends PrivacyCenterAuthContext {
  /** When true, the data subject is actively logging in */
  isLoggingIn: boolean;
  /** The session profile, when provided indicates there is a logged-in user */
  sessionProfile?: SombraProfile;
  /** The core identifier that was returned by sombra */
  coreIdentifier?: SombraCoreIdentifier;
  /** The privacyCenter configuration */
  privacyCenter?: PrivacyCenter;
  /** Should the privacy center be white labeled */
  whiteLabelPrivacyCenter?: boolean;
  /** Should the about transcend page be disabled */
  disabledPrivacyCenterAboutTranscend?: boolean;
  /** The currently selected partition */
  partition?: string;
  /** The session profiles for child organizations */
  childSessionProfiles?: ChildSessionProfile[];
}

export const appSlice = createSlice({
  name: 'privacy-center',
  initialState: {
    isLoggingIn: false,
  } as PrivacyCenterState,
  reducers: {
    setIsLoggingIn: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** True if logging in */
        isLoggingIn: boolean;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    setPrivacyCenter: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** Updated privacy center */
        privacyCenter: PrivacyCenter;
        /** Updated sombra public key */
        sombraPublicKey: string;
        /** Should the privacy center be white labeled */
        whiteLabelPrivacyCenter: boolean;
        /** Should the about transcend page be disabled */
        disabledPrivacyCenterAboutTranscend: boolean;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    setSession: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** Updated auth secret */
        authSecret: string;
        /** The session profile, when provided indicates there is a logged-in user */
        sessionProfile: SombraProfile;
        /** The core identifier that was returned by sombra */
        coreIdentifier: SombraCoreIdentifier;
      }>,
    ) => ({
      ...state,
      ...payload,
      authMethod: SombraDataSubjectAuthMethod.Session,
    }),
    setPartition: (
      state,
      {
        payload,
      }: PayloadAction<{
        /** The currently selected partition */
        partition: string;
      }>,
    ) => ({
      ...state,
      ...payload,
    }),
    setChildSessions: (
      state,
      {
        payload,
      }: PayloadAction<
        {
          /** organization id */
          organizationId: string;
          /** Updated auth secret */
          authSecret: string;
          /** The session profile, when provided indicates there is a logged-in user */
          sessionProfile: SombraProfile;
          /** The core identifier that was returned by sombra */
          coreIdentifier: SombraCoreIdentifier;
        }[]
      >,
    ) => ({
      ...state,
      childSessionProfiles: payload.map((childSession) => ({
        ...childSession,
        authMethod: SombraDataSubjectAuthMethod.Session,
      })),
    }),
    clearPrivacyCenter: (state) => ({
      ...state,
      privacyCenter: undefined,
      partition: undefined,
    }),
    endSession: (state) => ({
      ...state,
      authSecret: undefined,
      authMethod: undefined,
      sessionProfile: undefined,
      coreIdentifier: undefined,
    }),
    endChildSessions: (state) => ({
      ...state,
      childSessionProfiles: undefined,
    }),
  },
});

export const {
  setIsLoggingIn,
  setPrivacyCenter,
  setSession,
  setChildSessions,
  setPartition,
  endSession,
  clearPrivacyCenter,
  endChildSessions,
} = appSlice.actions;
